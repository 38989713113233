<template>
    <div class="login-container">
        <div class="login-title">WELCOME TO IMU NET GROUP</div>
        <div class="login-form">
            <div class="login-row"></div>
            <van-cell-group inset>
                <van-cell>
                    <van-field
                        v-model="user.username"
                        label="用户名"
                        placeholder="请输入用户名"
                    />
                </van-cell>
                <van-cell>
                    <van-field
                        v-model="user.password"
                        label="密码"
                        type="password"
                        placeholder="请输入密码"
                    />
                </van-cell>
                <van-cell>
                    <van-button type="info" block style="bg-color: #2d8cf0" @click="login()">登录</van-button>
                </van-cell>
            </van-cell-group>
        </div>
    </div>
</template>

<script>
import { login }  from '@/api/common'
import { setToken } from '@/utils/auth'
export default {
    components: {},
    data() {
        return {
            user: {
                username: "",
                password: "",
            },
        };
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {
        login() {
            if(this.user.username === '' || this.user.password === '') {
                this.$toast('请输入用户名/密码！');
            }else {
                const data = {
                    userName: this.user.username,
                    userPassword: this.user.password,
                    loginFlag: 1
                }
                login(data).then(res=>{
                    if(res.code === 20000) {
                        setToken(res.data);
                        this.$toast('登录成功！');
                        this.$router.push({ name: "Home" });
                        location.reload()
                    }else {
                        this.$toast('用户名/密码错误！');
                    }
                })
            }
        }
    },
};
</script>

<style scoped>
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-title {
    margin-top: 3rem;
    margin-bottom: 2rem;
    font-weight: bolder;
}

.login-form {
    width: 100%;
}
</style>